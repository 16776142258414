"use client";
import Image from "next/image";
import Link from "next/link";
import { useTenantRouter } from "@repo/libs/providers/tenant/client";

type TenantInfo = {
  image: string;
  name: string;
  subtitle: string;
};
export default function TenantInfo({ name, image, subtitle }: TenantInfo) {
  const router = useTenantRouter();
  return (
    <Link href={router("/products")} className="flex gap-x-4">
      <span className="rounded-full bg-white">
        <Image
          src={image ?? "https://cdn.rekaz.io/assets/placeholder.webp"}
          alt="logo"
          className="h-10 object-contain"
          width={100}
          height={100}
          loading="eager"
          priority
        />
      </span>
      <div className="flex flex-col justify-center">
        <h1 className="font-semibold">{name}</h1>
        <h3 className="font-thin  text-sm">{subtitle}</h3>
      </div>
    </Link>
  );
}
