"use client";

import { usePathname } from "next/navigation";

import {
  ComponentConfigurationDto,
  WebsiteConfigurationDto,
} from "@repo/website-toolkit/src/lib/configuration-dtos";
import RenderComponent from "@repo/website-toolkit/src/render-component";

export default function WrappedRender({
  websiteConfig,
  component,
  tenant,
}: {
  websiteConfig: WebsiteConfigurationDto;
  component: ComponentConfigurationDto;
  tenant: string;
}) {
  const path = usePathname();

  const pagePath = path.replace(`/${tenant}`, "");

  return (
    <RenderComponent
      componentConfig={component}
      websiteConfig={websiteConfig}
      // @ts-ignore
      currentPage={{
        path: pagePath,
      }}
    />
  );
}
