"use client";

import * as React from "react";

import * as SheetPrimitive from "@radix-ui/react-dialog";
import { cva, type VariantProps } from "class-variance-authority";
import { X } from "lucide-react";

import { cn } from "../lib/utils";

const SheetThemeContext = React.createContext<"customer" | "rekaz">("customer");

const overlayThemeStyles = {
  customer: "bg-black/80",
  rekaz: "bg-black/80",
};

const contentThemeStyles = {
  customer: "bg-theme-background border-theme-background-700",
  rekaz: "bg-white border-neutral-200",
};

const closeButtonThemeStyles = {
  customer:
    "ring-offset-theme-background data-[state=open]:bg-theme-background-600",
  rekaz: "ring-offset-white data-[state=open]:bg-neutral-100",
};

const titleThemeStyles = {
  customer: "text-theme-text",
  rekaz: "text-neutral-950",
};

const descriptionThemeStyles = {
  customer: "text-theme-text opacity-75",
  rekaz: "text-neutral-500",
};

interface SheetProps extends SheetPrimitive.DialogProps {
  theme?: "customer" | "rekaz";
}

const Sheet = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Root> & SheetProps
>(({ theme = "customer", children, ...props }, ref) => (
  <SheetThemeContext.Provider value={theme}>
    <SheetPrimitive.Root {...props}>{children}</SheetPrimitive.Root>
  </SheetThemeContext.Provider>
));

Sheet.displayName = SheetPrimitive.Root.displayName;

const SheetTrigger = SheetPrimitive.Trigger;

const SheetClose = SheetPrimitive.Close;

const SheetPortal = SheetPrimitive.Portal;

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => {
  const theme = React.useContext(SheetThemeContext);
  return (
    <SheetPrimitive.Overlay
      className={cn(
        "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50",
        theme === "customer"
          ? overlayThemeStyles.customer
          : overlayThemeStyles.rekaz,
        className,
      )}
      {...props}
      ref={ref}
    />
  );
});
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetVariants = cva(
  "fixed z-50 gap-4 p-6 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-300",
  {
    variants: {
      side: {
        top: "inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
        bottom:
          "inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
        left: "inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm",
        right:
          "inset-y-0 right-0 h-full w-3/4 border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm",
        center:
          "inset-0 mx-auto my-auto min-w-96 rounded-xl h-2/3 w-2/3 border-1 data-[state=closed]:fade-out data-[state=open]:fade-in sm:max-w-1/2",
      },
    },
    defaultVariants: {
      side: "right",
    },
  },
);

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
  VariantProps<typeof sheetVariants> {
  hideCloseIcon?: boolean;
}

const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(
  (
    { side = "right", hideCloseIcon = true, className, children, ...props },
    ref,
  ) => {
    const theme = React.useContext(SheetThemeContext);
    return (
      <SheetPortal>
        <SheetOverlay />
        <SheetPrimitive.Content
          ref={ref}
          className={cn(
            sheetVariants({ side }),
            theme === "customer"
              ? contentThemeStyles.customer
              : contentThemeStyles.rekaz,
            className,
          )}
          {...props}
        >
          {children}
          {!hideCloseIcon && (
            <SheetPrimitive.Close
              className={cn(
                "focus:ring-theme-primary absolute end-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 disabled:pointer-events-none",
                theme === "customer"
                  ? closeButtonThemeStyles.customer
                  : closeButtonThemeStyles.rekaz,
              )}
            >
              <X className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </SheetPrimitive.Close>
          )}
        </SheetPrimitive.Content>
      </SheetPortal>
    );
  },
);
SheetContent.displayName = SheetPrimitive.Content.displayName;

const SheetHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-2 text-center sm:text-left",
      className,
    )}
    {...props}
  />
);
SheetHeader.displayName = "SheetHeader";

const SheetFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className,
    )}
    {...props}
  />
);
SheetFooter.displayName = "SheetFooter";

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => {
  const theme = React.useContext(SheetThemeContext);
  return (
    <SheetPrimitive.Title
      ref={ref}
      className={cn(
        "text-lg font-semibold",
        theme === "customer"
          ? titleThemeStyles.customer
          : titleThemeStyles.rekaz,
        className,
      )}
      {...props}
    />
  );
});
SheetTitle.displayName = SheetPrimitive.Title.displayName;

const SheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => {
  const theme = React.useContext(SheetThemeContext);
  return (
    <SheetPrimitive.Description
      ref={ref}
      className={cn(
        "text-sm",
        theme === "customer"
          ? descriptionThemeStyles.customer
          : descriptionThemeStyles.rekaz,
        className,
      )}
      {...props}
    />
  );
});
SheetDescription.displayName = SheetPrimitive.Description.displayName;

export {
  Sheet,
  SheetPortal,
  SheetOverlay,
  SheetTrigger,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetFooter,
  SheetTitle,
  SheetDescription,
};
