"use client";

import * as React from "react";

import { cn } from "../lib/utils";

const CardThemeContext = React.createContext<"customer" | "rekaz">("customer");

const themeStyles = {
  customer: "border-theme-background-700 bg-theme-background text-theme-text",
  rekaz: "border-neutral-200 bg-white text-neutral-950",
};

const titleThemeStyles = {
  customer: "text-theme-text",
  rekaz: "text-neutral-950",
};

const descriptionThemeStyles = {
  customer: "opacity-75",
  rekaz: "text-neutral-500",
};

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { theme?: "customer" | "rekaz" }
>(({ className, theme = "customer", children, ...props }, ref) => (
  <CardThemeContext.Provider value={theme}>
    <div
      ref={ref}
      className={cn(
        "rounded-lg border shadow-sm",
        theme === "customer" ? themeStyles.customer : themeStyles.rekaz,
        className,
      )}
      {...props}
    >
      {children}
    </div>
  </CardThemeContext.Provider>
));
Card.displayName = "Card";

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-1.5 p-6", className)}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => {
  const theme = React.useContext(CardThemeContext);
  return (
    <h3
      ref={ref}
      className={cn(
        "text-2xl font-semibold leading-none tracking-tight",
        theme === "customer"
          ? titleThemeStyles.customer
          : titleThemeStyles.rekaz,
        className,
      )}
      {...props}
    />
  );
});
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const theme = React.useContext(CardThemeContext);
  return (
    <p
      ref={ref}
      className={cn(
        "text-sm",
        theme === "customer"
          ? descriptionThemeStyles.customer
          : descriptionThemeStyles.rekaz,
        className,
      )}
      {...props}
    />
  );
});
CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("p-6 pt-0", className)} {...props} />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center p-6 pt-0", className)}
    {...props}
  />
));
CardFooter.displayName = "CardFooter";

export {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
};
