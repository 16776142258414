"use client";
import { ShoppingCart } from "lucide-react";
import { Button } from "@repo/ui/components/button";
import { useCart } from "@website-toolkit/components/Providers/CartProvider";

export default function CartButton() {

  const { cartItems, toggleSheet } = useCart();
  return (
    <Button
      className="relative bg-white hover:bg-gray-100 active:bg-gray-100 focus:bg-gray-100 rounded-full aspect-square p-0"
      onClick={toggleSheet}
    >
      <ShoppingCart className="text-sm p-0.5 text-gray-700" />
      <span className="absolute text-sm text-center text-white -top-1 -end-1 w-4 h-4 bg-red-400 p-1 rounded-full">
        <p className="-translate-y-[6px]">{cartItems?.length ?? 0}</p>
      </span>
    </Button>
  );
}
